import { combineReducers } from 'redux';

import { 
    SELECT_POST_CALL_SURVEY,
    CHANGE_TENANT,
    INVALIDATE_HTTP_DATA, 
} from '../actions/typeConstants';

const id = 'name';

const auditHistory = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;


    case 'POSTCALLSURVEYS_HTTP_SUCCESS':

        if (!action.auditHistory) {
            return state;
        }

        // If this is for an object, return
        if (action.reqObject) {
            return state;
        }

        return Object.assign([], state, action.data);
    default:
        return state;
    }
};

const data = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case 'POSTCALLSURVEYS_HTTP_SUCCESS':

        if (action.auditHistory) {

            const newState = Object.assign([], state);

            const found = state.findIndex(obj => obj[id] == action.reqObject);
            if (found === -1 || !newState[found]) {
                return state;
            }

            newState[found].auditHistory = action.data;

            return Object.assign([], newState);
        }

        if (action.reqObject) {

            const newState = Object.assign([], state);
            const found = state.findIndex(obj => obj && obj[id] === action.reqObject);

            if (!action.data) {
                delete newState[found];
            } else if (found === -1) {
                newState.push(action.data.data);
            } else {
                newState[found] = action.data.data;
            }

            return Object.assign([], newState);
        }

        return Object.assign([], state, action.data.data);
    default:
        return state;
    }
};

const metaData = (state = null, action) => {
    

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;


    case 'POSTCALLSURVEYS_HTTP_SUCCESS':

        if (!action.data || !action.data.metaData || action.reqObect) {
            return state;
        }

        return action.data.metaData;
    default:
        return state;
    }
};

const pending = (state = false, action) => {

    switch (action.type) {

    case 'POSTCALLSURVEYS_HTTP_PENDING':
        return true;

    case 'POSTCALLSURVEYS_HTTP_FAILURE':
    case 'POSTCALLSURVEYS_HTTP_SUCCESS':
        return false;

    default:
        return state;
    }
};

const selected = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case SELECT_POST_CALL_SURVEY:
        return action.data;

    default:
        return state;
    }
};

export const postCallSurveys = combineReducers({
    auditHistory,
    data,
    metaData,
    pending,
    selected,
});
