import React, { Component } from 'react';
import { connect } from 'react-redux';

import request from 'superagent';

import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/styles';

import ActionCard from 'presentational/ActionCard';
import DetailContainer from 'presentational/DetailContainer';
import LeftBar from 'presentational/LeftBar';
import MasterHistory from 'presentational/MasterHistory';
import MasterView from 'presentational/MasterView';
import StandardDetailsPage from 'presentational/StandardDetailsPage';

import PostCallSurveyHelp from './help';
import NewPostCallSurvey from './new';
import PostCallSurveySettings from './settings';
import PostCallSurveyCallerQuestions from './callerQuestions';
import PostCallSurveyAgentQuestions from './agentQuestions';

import {
    deleteCoreHttp,
    getCoreHttp,
    postCoreHttp,
    putCoreHttp,
} from 'actions/httpRequest';

import { selectPostCallSurvey } from 'actions/postCallSurveys';

import { successNotification, errorNotification } from 'actions/notifications';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const styles = () => ({
    menuItem: {
        fontSize: '100%',
    },
  });

class PostCallSurveys extends Component {

    constructor(props) {
        super(props);
        this.state = {
            postCallSurveysFetchError: null,
            leftBarData: null,
            route: null,
            selectedPostCallSurvey: null,
            closeMenu: null,
            showDeleteConfirmationModal: false,
        };
    }

    componentDidMount() {

        this.setState({
            route: null,
            selectedPostCallSurvey: null,
        });

        this.props.selectPostCallSurvey('');

        if (!this.props.postCallSurveys) {

            this.fetchPostCallSurveys();
        } else {

            this.prepareDataForLeftBar(this.props.postCallSurveys);
        }

        if (!this.props.recordings) {

            this.fetchRecordings();
        }
    }

    componentWillUnmount() {

        this.setState({
            route: null,
            selectedPostCallSurvey: null,
        });
        this.props.selectPostCallSurvey('');
    }

    componentWillReceiveProps(nextProps) {

        // If the new postCallSurveys object is different (or if we
        // didn't have one before) lets get the data ready for leftbar
        if (nextProps.postCallSurveys && this.props.postCallSurveys !== nextProps.postCallSurveys) {

            this.prepareDataForLeftBar(nextProps.postCallSurveys);
        }

        if (nextProps.selectedPostCallSurveyName && nextProps.postCallSurveys) {

            const selectedPostCallSurvey = nextProps.postCallSurveys.find(
                (obj) => obj && obj.name === nextProps.selectedPostCallSurveyName,
            );

            // If we have a new post call survey, handle this here
            if (nextProps.selectedPostCallSurveyName == 'New Post Call Survey') {
                this.setState({ selectedPostCallSurvey: { name: 'New Post Call Survey' } });
                return;
            }

            if (!selectedPostCallSurvey) {
                console.error('No post call survey found by the name of: ', nextProps.selectedPostCallSurveyName);
                return;
            }

            // If the name has changed, lets reset the route here
            if (nextProps.selectedPostCallSurveyName !== this.props.selectedPostCallSurveyName || nextProps.postCallSurveys.length !== this.props.postCallSurveys.length) {
                this.setState({
                    selectedPostCallSurvey,
                    route: '',
                });
            } else {
                this.setState({ selectedPostCallSurvey });
            }
        }
    }

    // fetchPostCallSurveys acquires post call survey data data via redux api
    fetchPostCallSurveys() {

        // Lets set up the request data to retreive post call survey data
        const reqData = {
            reqAction: 'postcallsurveysconfiguration',
        };

        const storeKey = 'postCallSurveys';

        this.props.getCoreHttp(reqData, storeKey);
    }

    // fetchPostCallSurveys acquires post call survey data data via redux api
    fetchRecordings() {

        // Lets set up the request data to retreive post call survey data
        const reqData = {
            reqAction: 'recording',
        };

        const storeKey = 'recordings';

        this.props.getCoreHttp(reqData, storeKey);
    }

    // prepareDataForLeftBar maps through all post call surveys received
    // via http and formats data objects to be consumed by the leftBar
    prepareDataForLeftBar(data) {

        // return on no data
        if (!data) {
            return console.log('No post call surveys returned');
        }

        // array we will push our new object into
        const leftBarData = [];
        data.map((item) => {

            // Set the title to friendly name if we have it, otherwise just the name
            const title = item.friendlyname ? item.friendlyname : item.name;
            const subtitle = '';
            const id = item.name;

            // push the data into the leftBar array
            return leftBarData.push({ id, title, subtitle });
        });

        this.setState({ leftBarData: null });

        // finally, lets set the leftBarData to state to update leftbar
        return this.setState({ leftBarData });
    }

    leftBarSelect(postCallSurveyName) {

        this.setState({ route: null });

        this.props.selectPostCallSurvey(postCallSurveyName);

        this.getPostCallSurveyDetail(postCallSurveyName);
    }

    getPostCallSurveyDetail(postCallSurveyName) {

        const reqData = {
            reqAction: 'postcallsurveysconfiguration',
            reqObject: postCallSurveyName,
        };

        const storeKey = 'postCallSurveys';

        this.props.getCoreHttp(reqData, storeKey);
    }

    getPostCallSurveyAuditHistory(postCallSurveyName) {

        const reqData = {
            auditHistory: true,
            reqAction: 'postcallsurveysconfiguration',
            reqObject: postCallSurveyName,
        };

        const storeKey = 'postCallSurveys';

        this.props.getCoreHttp(reqData, storeKey);
    }

    handleAddNewClick() {

        this.props.selectPostCallSurvey('New Post Call Survey');
        this.navigate('New');
    }

    handleDeletePostCallSurvey() {

        if (!this.state.selectedPostCallSurvey) {
            console.log('No post call survey selected');
            return;
        }

        const { name } = this.state.selectedPostCallSurvey;

        const reqData = {
            reqAction: 'postcallsurveysconfiguration',
            reqObject: name,
        };

        const storeKey = 'postCallSurveys';

        this.props.deleteCoreHttp(reqData, storeKey);

        // Lets set state to clear the route and selected post call survey
        this.setState({
            route: '',
            selectedPostCallSurvey: null,
        });
        this.props.selectPostCallSurvey('');

        // Lets trigger a notification
        successNotification({
            title: 'Success!',
            message: `Deleted ${name}`,
        });
    }

    createNewPostCallSurvey(newName) {

        const reqData = {
            reqAction: 'postcallsurveysconfiguration',
            reqObject: newName,
            reqBody: { name: newName, friendlyname: newName },
        };

        const storeKey = 'postCallSurveys';

        this.props.postCoreHttp(reqData, storeKey);
    }

    submitSettingsForm(data) {

        const reqData = {
            reqAction: 'postcallsurveysconfiguration',
            reqObject: data.name,
            reqBody: data,
        };

        const storeKey = 'postCallSurveys';

        this.props.putCoreHttp(reqData, storeKey);

        //TODO JULIETTE: Need to check here if this is actually successful or not. We dont
        //want a success message if the request failed.

        successNotification({ title: 'Post Call Survey Updated!', message: `Successfully updated survey ${data.name}` });
    }

    goToHelp() {
        const win = window.open('http://help.fluentcloud.com/support/solutions', '_blank');
        win.focus();
    }

    postCallSurveyRoutes() {

        return ([
          <ActionCard
            key="Settings"
            title="Settings"
            subtitle="Manage survey level settings for post call survey"
            action={() => this.navigate('Settings')}
          />,
          <ActionCard
            key="Caller Questions"
            title="Caller Questions"
            subtitle="Manage questions sent to caller for post call survey"
            action={() => this.navigate('Caller Questions')}
          />,
          <ActionCard
            key="Agent Questions"
            title="Agent Questions"
            subtitle="Manage questions sent to agent for post call survey"
            action={() => this.navigate('Agent Questions')}
          />,
          <ActionCard
            key="History"
            title="History"
            subtitle="See who made changes to this post call survey"
            action={() => {
                this.getPostCallSurveyAuditHistory(this.props.selectedPostCallSurveyName);
                this.navigate('History');
            }}
          />,
        ]);
    }

    navigate(route) {

        this.setState({ route });
    }

    openCloseMenu() {
        this.setState({ closeMenu: !this.state.closeMenu });
    }

    renderContent() {

        const {
            postCallSurveys,
            selectedPostCallSurveyName,
        } = this.props;

        const { route } = this.state;

        if (!selectedPostCallSurveyName || !postCallSurveys) {
            return <PostCallSurveyHelp />;
        }

        const selectedPostCallSurvey = postCallSurveys.find((obj) => obj && selectedPostCallSurveyName === obj.name);

        switch (route) {

        case 'Caller Questions':
            return (
              <PostCallSurveyCallerQuestions
                submitForm={(data) => this.submitSettingsForm(data)}
                data={selectedPostCallSurvey}
              />
            );

        case 'Agent Questions':
            return (
              <PostCallSurveyAgentQuestions
                submitForm={(data) => this.submitSettingsForm(data)}
                data={selectedPostCallSurvey}
              />
            );

        case 'New':
            return (
              <NewPostCallSurvey
                submitForm={(data) => this.createNewPostCallSurvey(data)}
                selectPostCallSurvey={(name) => this.props.selectPostCallSurvey(name)}
              />
            );

        case 'Settings':
            return (
              <PostCallSurveySettings
                submitForm={(data) => this.submitSettingsForm(data)}
                data={selectedPostCallSurvey}
              />
            );

        case 'History':

            return <MasterHistory data={selectedPostCallSurvey.auditHistory} />;
        default:
            return <StandardDetailsPage cards={this.postCallSurveyRoutes()} />;
        }
    }

    render() {

        const {
            postCallSurveys,
            pending,
            selectedPostCallSurveyName,
            classes,
        } = this.props;

        const { leftBarData, route, selectedPostCallSurvey } = this.state;

        let title = '';

        if (postCallSurveys && postCallSurveys) {

            if (selectedPostCallSurvey && selectedPostCallSurvey.friendlyname) {
                title = selectedPostCallSurvey.friendlyname;
            } else if (selectedPostCallSurvey && selectedPostCallSurvey.name) {
                title = selectedPostCallSurvey.name;
            }
        }

        const contextMenuOptions = [
          <MenuItem
            disabled={!selectedPostCallSurvey}
            classes={{ root: classes.menuItem }}
            onClick={() => {
                this.setState({showDeleteConfirmationModal: true});
                this.openCloseMenu();
            }}
            key={0}
          >Delete</MenuItem>,
          <MenuItem
            onClick={() => {this.goToHelp(); this.openCloseMenu();}}
            classes={{ root: classes.menuItem }}
            key={1}
          >Help</MenuItem>,
        ];
        
        const deleteConfirmationModal = [
            <Dialog
                open={this.state.showDeleteConfirmationModal}
                onClose={() => {
                    this.setState({showDeleteConfirmationModal: false});
                }}
                fullWidth={true}
            >
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deleting this survey will also permanently delete all associated survey results. This action
                        cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color='primary'
                        onClick={() => {
                            this.setState({showDeleteConfirmationModal: false});
                        }}
                    >Cancel</Button>
                    <Button
                        color='primary'
                        onClick={() => {
                            this.handleDeletePostCallSurvey();
                            this.setState({showDeleteConfirmationModal: false});
                        }}
                    >Delete</Button>
                </DialogActions>
            </Dialog>
        ]

        return (
          <MasterView>
            <DetailContainer
              leftBar={
                <LeftBar
                  addNew={() => this.handleAddNewClick()}
                  data={leftBarData}
                  pending={pending}
                  noDescription
                  selected={selectedPostCallSurveyName}
                  select={(id) => this.leftBarSelect(id)}
                  title="Post Call Surveys"
                />
              }
              title={title}
              route={route != 'New' ? route : null}
              return={() => this.setState({ route: null })}
              contextMenuOptions={contextMenuOptions}
              closeMenu={this.state.closeMenu}
            >
              {this.renderContent()}
              {deleteConfirmationModal}
            </DetailContainer>
          </MasterView>
        );
    }

}

const bindActions = (dispatch) => ({
    deleteCoreHttp: (reqData, storeKey) => dispatch(deleteCoreHttp(reqData, storeKey)),
    
    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
    postCoreHttp: (reqData, storeKey) => dispatch(postCoreHttp(reqData, storeKey)),
    putCoreHttp: (reqData, storeKey) => dispatch(putCoreHttp(reqData, storeKey)),
    
    selectPostCallSurvey: (survey) => dispatch(selectPostCallSurvey(survey)),
});

const mapStateToProps = (state) => ({
    postCallSurveys: state.postCallSurveys.data,
    metaData: state.postCallSurveys.metaData,
    selectedPostCallSurveyName: state.postCallSurveys.selected,
    pending: state.postCallSurveys.pending,
    recordings: state.recordings.data,
});

export default connect(mapStateToProps, bindActions)(withStyles(styles)(PostCallSurveys));
