import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/styles';
import CallerQuestionForm from './callerQuestionForm';

import AgentQuestionForm from './agentQuestionForm';

const styles = () => ({
  button: {
      fontSize: '100%',
  },
  card: {
    width: '50%',
    minWidth: '400px',
  },
  cardSmall: {
    width: '100%',
  },
  deleteButton: {
      color: 'red',
  },
});

class QuestionCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showingModal: false,
            showModifyConfirmationDialog: false,
            showDeleteConfirmationDialog: false,
            mediaQuery: false,
            form: props.form,
            initialForm: {},
            formErrors: {
                question_name: '',
                question_text: '',
                rating_min: '',
                rating_max: '',
            },
        };
    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
    }

    handleClose(submit) {

        const { context, data, id } = this.props;

        if (submit) {

            this.setState({ formErrors: {} });

            const formErrors = this.validateForm();

            if (Object.keys(formErrors).some(err => formErrors[err])) {

                this.setState({ formErrors })
                return;
            }


            this.props.submitForm(this.state.form);
            this.setState({ 
                showingModal: false, 
                showModifyConfirmationDialog: false 
            });
            return;
        }

        this.setState({ 
            showingModal: false, 
            showModifyConfirmationDialog: false,
            form: this.state.initialForm 
        });
    }

    validateForm() {
        const { context, form, id } = this.props;

        const foundIndex = this.state.form.questions[context].findIndex(
            (obj) => obj && obj.question_id === id,
        );

        const currentQuestionForm = this.state.form.questions[context][foundIndex];

        let formErrors = {};
        const max = parseInt(currentQuestionForm['rating_max']) || parseInt(currentQuestionForm['dialpad_max']);
        const min = parseInt(currentQuestionForm['rating_min']) || parseInt(currentQuestionForm['dialpad_min']);

        if (min >= max) {
            formErrors['dialpad_max'] = 'Maximum rating must be higher than minimum rating';
        }

        return formErrors;
    }

    updateForm(field, value) {
        const { form } = this.state;

        const { context, id } = this.props;

        const foundIndex = this.state.form.questions[context].findIndex(
            (obj) => obj && obj.question_id === id,
        );

        console.log('UPDATING FORM: ', foundIndex, form.questions[context][foundIndex][field])

        form.questions[context][foundIndex][field] = value;
        this.setState({ form });
    }

    render() {

        const { context, data, id } = this.props;
         const { form } = this.state;
        const minWidth = this.state.mediaQuery.matches;

        const modalTitle = `Edit question for ${form.name}`;

        const foundIndex = this.state.form.questions[context].findIndex(
            (obj) => obj && obj.question_id === id,
        );

        const currentQuestionForm = form.questions[context][foundIndex];
        const {classes} = this.props;

        return (
          <Card classes={{ root: minWidth ? classes.card : classes.cardSmall }}>
            <CardHeader
              title={data.question_name}
              subheader={`Question Number ${data.question_number}`}
              action={
                <>
                  <IconButton
                    onClick={() => {
                      const initialForm = Object.assign({}, form);
                      this.setState({ initialForm, showingModal: true });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    classes={{ root: classes.deleteButton }}
                    onClick={() => this.setState({showDeleteConfirmationDialog: true})}
                  >
                    <DeleteIcon/>
                  </IconButton>
                </>
              }
            >
            </CardHeader>
            <Dialog
              open={this.state.showingModal}
              onClose={() => this.handleClose(false)}
              fullWidth={true}
            >
              <DialogTitle>{modalTitle}</DialogTitle>
              <DialogContent>
                {context === "call" ?
                  <CallerQuestionForm
                    updateForm={(field, value) => this.updateForm(field, value)}
                    form={currentQuestionForm}
                    formErrors={this.state.formErrors}
                  />
                  :
                  <AgentQuestionForm
                    updateForm={(field, value) => this.updateForm(field, value)}
                    form={currentQuestionForm}
                    formErrors={this.state.formErrors}
                  />
                }
              </DialogContent>
              <DialogActions>
                <Button
                  classes={{ root: classes.button }}
                  color='primary'
                  onClick={() => this.handleClose(false)}
                >Cancel</Button>
                <Button
                  classes={{ root: classes.button }}
                  color='primary'
                  onClick={() => this.setState({showModifyConfirmationDialog: true})}
                >Submit</Button>
              </DialogActions>
            </Dialog>

            <Dialog
                open={this.state.showModifyConfirmationDialog}
                onClose={() => this.setState({showModifyConfirmationDialog: false})}
            >
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                      By modifying these survey questions, you will break the link between 
                      the existing survey results and the updated questions. 
                      Historical data will still be available, but it will no longer be connected 
                      to the changed questions.
                      <br/><br/>
                      Are you sure you want to proceed with these changes?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                      <Button
                          classes={{ root: classes.button }}
                          color='primary'
                          onClick={() => this.setState({showModifyConfirmationDialog: false})}
                      >Cancel</Button>
                      <Button
                          classes={{ root: classes.button }}
                          color='primary'
                          onClick={() => this.handleClose(true)}
                      >Submit</Button>
                  </DialogActions>
              </Dialog>

              <Dialog
                  open={this.state.showDeleteConfirmationDialog}
                  onClose={() => this.setState({showDeleteConfirmationDialog: false})}
              >
                  <DialogTitle>Are you sure?</DialogTitle>
                  <DialogContent>
                      <DialogContentText>
                        By deleting this survey question, you will permanently remove it 
                        from the survey. Historical data related to this question will 
                        still be available, but it will no longer be associated with this survey.
                        <br/><br/>
                        Are you sure you want to proceed with deleting this question?
                      </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                      <Button
                          classes={{ root: classes.button }}
                          color='primary'
                          onClick={() => this.setState({showDeleteConfirmationDialog: false})}
                      >Cancel</Button>
                      <Button
                          classes={{ root: classes.button }}
                          color='primary'
                          onClick={() => {
                              this.setState({ showDeleteConfirmationDialog: false });
                              this.props.removeQuestion(id)
                          }}
                      >Delete</Button>
                  </DialogActions>
              </Dialog>
          </Card>
        );
    }
}

export default withStyles(styles)(QuestionCard);
